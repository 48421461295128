<template>
  <div class="w-full">
    <div
      v-if="!lvid"
      class="fixed top-0 left-0 w-full h-screen flex items-center justify-center z-50"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <div
      class="fixed z-[1] fsec h-screen top-0 left-0 right-0 max-h-screen overflow-hidden w-full"
    >
      <section
        class="w-full relative flex md:block flex-col items-center md:justify-between justify-start overflow-hidden md:max-h-screen md:min-h-0 min-h-screen bg-realBlack"
      >
        <div class="md:hidden block"></div>
        <div
          class="w-full relative md:min-h-0 md:max-h-none md:h-auto sm:h-auto sm:max-h-none h-[65vh] max-h-[65vh]"
        >
          <div
            class="w-full md:max-h-screen md:h-auto h-[65vh] max-h-[65vh] overflow-hidden bg-[#000] md:min-h-0"
            id="vd"
          >
            <video
              src="@/assets/videos/javanHighRes.mp4"
              ref="vid"
              class="w-full md:h-auto h-[65vh] md:scale-[1] scale-[3]"
              muted
              playsinline
              loop
            ></video>
          </div>
          <div
            class="absolute w-full h-full top-0 left-0 flex pb-[5vh] flex-col items-center justify-end bg-realBlack bg-opacity-35"
          >
            <div
              class="flex flex-col items-center md:justify-between justify-center h-full w-full"
            >
              <div class="w-full"></div>
              <div class="w-full">
                <h1
                  class="capitalize font-cocon text-white md:text-7xl text-4xl text-center font-medium tracking-[0.5rem]"
                >
                  Javan
                </h1>
                <p
                  class="uppercase font-inter tracking-[1.2px] text-center mt-4 font-light text-white"
                >
                  Starting from NGN{{ prcs.javan }},000,000.00
                </p>
                <p
                  class="uppercase font-inter tracking-[1.2px] text-sm text-center mt-4 font-light text-white"
                >
                  10% down payment required. Spread over 5 years.
                </p>
              </div>
              <div class="w-full md:block hidden">
                <div class="w-full flex items-center justify-center">
                  <router-link
                    :to="{ name: 'order-javan' }"
                    class="sec-btn font-inter font-normal inline-block text-black cursor-pointer"
                  >
                    Order Now
                  </router-link>
                </div>
                <div
                  class="flex items-center justify-between max-w-xl mx-auto w-[90%] gap-4 mt-12"
                >
                  <div class="text-center w-1/4 text-white">
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Dimension
                    </p>
                    <p class="font-inter text-xl mt-1">
                      L:3584x W:1475 x H:1666
                    </p>
                  </div>
                  <div class="ver"></div>
                  <div class="text-center w-1/4 text-white">
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Top Speed
                    </p>
                    <p class="font-inter text-xl mt-1">101Km/H</p>
                  </div>
                  <div class="ver"></div>
                  <div class="text-center w-1/4 text-white">
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Range
                    </p>
                    <p class="font-inter text-xl mt-1">220 km</p>
                  </div>
                  <div class="ver"></div>
                  <div class="text-center w-1/4 text-white">
                    <p class="font-inter text-xs uppercase tracking-[1.25px]">
                      Battery
                    </p>
                    <p class="font-inter text-xl mt-1">60v100ah</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          class="w-full md:hidden block md:pt-[10vh] pt-[5vh] sm:h-auto sm:max-h-none h-[30vh] max-h-[30vh]"
        >
          <div
            class="flex flex-col md:flex-row items-center justify-between max-w-xl mx-auto md:w-[90%] w-[95%] gap-4 pb-12"
          >
            <div class="md:w-2/5 w-full">
              <div class="w-full flex items-stretch justify-between">
                <div class="text-left md:w-auto w-1/2 text-white">
                  <p
                    class="font-inter text-center text-xs text-red uppercase tracking-[1.25px]"
                  >
                    Dimension
                  </p>
                  <p
                    class="font-inter text-center md:text-2xl text-sm whitespace-nowrap mt-1"
                  >
                    L:3584x W:1475 x H:1666
                  </p>
                </div>
                <div class="text-left md:w-auto w-[45%] text-white">
                  <p
                    class="font-inter text-center text-xs text-red uppercase tracking-[1.25px]"
                  >
                    Top Speed
                  </p>
                  <p
                    class="font-inter text-center md:text-2xl text-sm whitespace-nowrap mt-1"
                  >
                    101Km/H
                  </p>
                </div>
              </div>
            </div>

            <div class="md:w-2/5 w-full">
              <div class="w-full flex items-stretch justify-between">
                <div class="text-left md:w-auto w-[45%] text-white">
                  <p
                    class="font-inter text-center text-xs text-red uppercase tracking-[1.25px]"
                  >
                    Range
                  </p>
                  <p
                    class="font-inter text-center md:text-2xl text-sm whitespace-nowrap mt-1"
                  >
                    220 km
                  </p>
                </div>
                <div class="text-left md:w-auto w-[45%] text-white">
                  <p
                    class="font-inter text-center text-xs text-red uppercase tracking-[1.25px]"
                  >
                    Battery
                  </p>
                  <p
                    class="font-inter text-center md:text-2xl text-sm whitespace-nowrap mt-1"
                  >
                    60v100ah
                  </p>
                </div>
              </div>
            </div>
            <div class="md:w-1/5 w-auto flex items-center justify-center">
              <router-link
                :to="{ name: 'order-javan' }"
                class="sec-btn inline-block font-inter font-normal text-black mt-4"
              >
                Order Now
              </router-link>
            </div>
          </div>
        </section>
      </section>
    </div>
    <section
      class="w-full pin bg-white md:py-0 min-h-screen flex items-center py-[10vh]"
    >
      <div class="w-[90%] mx-auto max-w-xl">
        <p
          class="xl:w-11/12 md:w-9/12 w-full md:text-4xl text-2xl tracking-wider font-belgiano font-light"
        >
          Unleash the wild spirit of the Javan, inspired by the majestic
          leopard&rsquo;s fight for survival. With its habitat dwindling by 90%,
          we&rsquo;ve harnessed the power of innovation to create a vehicle that
          not only thrills but also helps preserve our planet&rsquo;s precious
          resources. Join the pride and experience the rush of Javan.
        </p>
      </div>
    </section>
    <section class="w-full md:max-h-screen overflow-hidden">
      <img
        src="@/assets/images/javan/int.png"
        alt=""
        class="w-full opacity-1"
      />
    </section>
    <section class="w-full bg-white pb-[5.5rem] overflow-hidden">
      <div class="mx-auto grid grid-cols-2 gap-4">
        <img
          src="@/assets/images/javan/top.png"
          alt=""
          class="w-full col-span-2"
        />
      </div>
      <div class="max-w-xl w-[90%] mx-auto md:mt-[20vh] mt-24 relative">
        <h3
          class="font-belgiano md:text-5xl text-2xl w-full text-left capitalize mb-8 md:leading-[3.5rem]"
        >
          Minimalism Meets Mastery: Embrace the electric vehicle that redefines
          urban driving.
        </h3>
        <div
          class="w-full relative md:overflow-visible overflow-hidden"
          @mouseenter="pauseSlide"
          @mouseleave="nextSlide"
        >
          <div
            class="w-[300%] flex items-stretch slidr"
            :style="`margin-left: -${slide * 100}%`"
          >
            <div class="w-full relative">
              <img
                src="@/assets/images/javan/cabin.png"
                class="w-full"
                alt=""
              />
              <p class="font-inter font-medium md:text-2xl text-xl mt-6 mb-1">
                Spacious Cabin
              </p>
              <p class="font-inter">
                With ample legroom, everyone enjoys first-class comfort.
              </p>
              <div
                class="absolute top-0 right-0 bottom-0 left-0 w-full h-full bg-white slidr bg-opacity-60 backdrop-blur-[2px]"
                v-if="slide !== 0"
              ></div>
            </div>
            <div class="w-full relative">
              <img src="@/assets/images/javan/dash.png" class="w-full" alt="" />
              <p class="font-inter font-medium md:text-2xl text-xl mt-6 mb-1">
                Detailed Dashboard
              </p>
              <p class="font-inter">
                Get a clear view of your vehicle's performance in one screen.
              </p>
              <div
                class="absolute top-0 right-0 bottom-0 left-0 w-full h-full bg-white slidr bg-opacity-60 backdrop-blur-[2px]"
                v-if="slide !== 1"
              ></div>
            </div>
            <div class="w-full relative">
              <img
                src="@/assets/images/javan/hatch.png"
                class="w-full"
                alt=""
              />
              <p class="font-inter font-medium md:text-2xl text-xl mt-6 mb-1">
                Elegant Hatchback
              </p>
              <p class="font-inter">
                Designed to be simple, but highly efficient.
              </p>
              <div
                class="absolute top-0 right-0 bottom-0 left-0 w-full h-full bg-white slidr bg-opacity-60 backdrop-blur-[2px]"
                v-if="slide !== 2"
              ></div>
            </div>
          </div>
          <div
            class="flex items-center md:justify-between justify-end md:absolute md:right-10 mt-4 md:bottom-0 gap-6 bg-white"
          >
            <img
              src="@/assets/images/javan/rightArr.png"
              class="rotate-180 cursor-pointer"
              @click="slide = slide === 0 ? 2 : slide - 1"
              alt=""
            />
            <img
              src="@/assets/images/javan/rightArr.png"
              class="cursor-pointer"
              @click="slide = slide === 2 ? 0 : slide + 1"
              alt=""
            />
          </div>
        </div>
        <h4
          class="font-belgiano font-medium md:text-4xl text-2xl w-full text-left capitalize mt-[5.5rem] md:mb-[5.4rem] mb-6"
        >
          Empower Your Journey:
        </h4>
        <div
          class="w-full flex flex-col-reverse md:flex-row justify-between items-center md:gap-0 gap-8 md:pb-[5.5rem]"
        >
          <div class="md:w-5/12 w-full lg:w-6/12">
            <img src="@/assets/images/javan/illus.png" class="w-full" alt="" />
          </div>
          <ul
            class="font-inter text-base md:w-7/12 w-full lg:w-6/12 pl-4 list-disc"
          >
            <li class="md:text-left text-justify">
              <span class="font-inter font-bold">Long-Range Capability:</span>
              With a range of up to 220 miles, take on extended off-road
              expeditions.
            </li>
            <li class="mt-3 md:text-left text-justify">
              <span class="font-inter font-bold">Qoray Charge Station:</span>
              Charges up to 100 miles of range in just 15 minutes. Compact,
              mountable indoors or outdoors, and Wi-Fi enabled.
            </li>
            <li class="mt-3 md:text-left text-justify">
              <span class="font-inter font-bold">Home Charging:</span>
              Our Javan comes with a home charging device to allow you power up
              your car from the comfort of your home.
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "vehicles",
  components: { Loading },
  data() {
    return {
      l1: false,
      l2: false,
      lvid: false,
      timeout: "",
      slide: 0,
    };
  },
  methods: {
    orderJavan() {
      this.$store.dispatch("orderNow", "Javan");
    },
    nextSlide() {
      // console.log("time");
      this.timeout = setTimeout(() => {
        this.slide = this.slide === 2 ? 0 : this.slide + 1;
      }, 5000);
    },
    prevSlide() {
      // this.timeout = setTimeout(() => {
      //   this.slide = this.slide === 0 ? 2 : this.slide - 1;
      // }, 5000);
    },
    pauseSlide() {
      clearTimeout(this.timeout);
    },
    animate() {
      // gsap.to(".firstBg", {
      //   scale: 1,
      //   duration: 2.5,
      //   ease: "power4.out",
      // });
      // gsap.to(".sav", {
      //   y: 0,
      //   scale: 1,
      //   duration: 2.5,
      //   ease: "power4.out",
      // });
    },
  },
  computed: {
    loaded() {
      return this.l1 && this.l2;
    },
    prcs() {
      return this.$store.getters.dispPrices;
    },
  },
  watch: {
    slide() {
      this.pauseSlide();
      this.nextSlide();
    },
    loaded() {
      this.animate();
    },
    lvid() {
      this.$refs.vid.play();
    },
  },
  mounted() {
    this.$refs.vid.load();
    this.$refs.vid.addEventListener(
      "canplaythrough",
      () => {
        gsap.set(".img1", {
          opacity: 0,
        });
        gsap.to(".load1", {
          yPercent: 100,
          duration: 0.3,
          ease: "easeIn",
        });
        gsap.to(".load2", {
          xPercent: 100,
          duration: 0.3,
          ease: "easeIn",
          onComplete: () => {
            // this.loading = false;
            this.lvid = true;
          },
        });
      },
      false
    );
    gsap.to(".fsec", {
      height: 0,
      scrollTrigger: {
        scrub: true,
        trigger: ".fsec",
        start: "top top",
        end: "bottom top",
        // markers: true,
        pin: ".pin",
      },
    });
    // gsap.set(".firstBg", {
    //   scale: 1.6,
    // });
    // gsap.set(".sav", {
    //   scale: 0.6,
    //   y: "-80vh",
    // });
    // this.$refs.loadd.addEventListener(
    //   "load",
    //   () => {
    //     this.l1 = true;
    //   },
    //   false
    // );
    // this.$refs.loadd1.addEventListener(
    //   "load",
    //   () => {
    //     this.l2 = true;
    //   },
    //   false
    // );
  },
};
</script>

<style>
.intr {
  background-image: url("@/assets/images/javan/int.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: left top;
}
.secSta {
  background-image: url("@/assets/images/vehiclesPage/chargeStation.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
.slidr {
  transition: all 0.5s ease-out;
}
.ver {
  @apply inline-block w-px h-8 bg-white bg-opacity-25;
}
</style>
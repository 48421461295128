<template>
  <form class="w-full *:text-white">
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      v-model="name"
      placeholder="Name"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.name }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      v-model="phone_number"
      placeholder="Phone number"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.phone_number }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="email"
      v-model="email"
      placeholder="Email address"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.email }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      v-model="organization"
      placeholder="Organization (Optional)"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.organization }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      v-model="role"
      placeholder="Role (Optional)"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.role }}</p>
    <select
      v-model="service"
      class="w-full bg-[#303030] mt-4 py-4 px-5 rounded-lg"
    >
      <option value="" disabled selected>Service of Interest</option>
      <option value="Financing">Financing</option>
      <option value="Switch">Switch</option>
      <option value="Energy networks">Energy networks</option>
      <option value="Manufacturing, Assembly and Sales">
        Manufacturing, Assembly and Sales
      </option>
      <option value="Mobility as a Service">Mobility as a Service</option>
      <option value="Tour with Qoray">Tour with Qoray</option>
    </select>
    <p class="mt-1 !text-red text-xs">{{ errs.service }}</p>
    <textarea
      name=""
      id=""
      class="w-full resize-none bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      cols="30"
      rows="4"
      v-model="description"
      placeholder="Request description (Optional)"
    ></textarea>
    <p class="mt-1 !text-red text-xs">{{ errs.description }}</p>
    <button class="red-btn w-full mt-4" @click.prevent="submit">Submit</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      phone_number: "",
      email: "",
      organization: "",
      role: "",
      service: "",
      description: "",
      errs: {
        name: "",
        phone_number: "",
        email: "",
        organization: "",
        role: "",
        service: "",
        description: "",
      },
    };
  },
  computed: {
    today() {
      // Get today's date
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const dd = String(today.getDate()).padStart(2, "0");

      // Format the date as YYYY-MM-DD
      const formattedToday = `${yyyy}-${mm}-${dd}`;
      return formattedToday;
    },
    payload() {
      return {
        name: this.name,
        phone_number: this.phone_number,
        email: this.email,
        organization: this.organization,
        role: this.role,
        service: this.service,
        description: this.description,
      };
    },
  },
  methods: {
    success() {
      this.$store.dispatch("modalSuccess", {
        title: "Request Submitted",
        top: `Your submission has been received.`,
        bottom: "A sales rep will reach out to you in 72 hours",
      });
    },
    validateForm() {
      this.errs = {
        name: "",
        phone_number: "",
        email: "",
        organization: "",
        role: "",
        service: "",
        description: "",
      };
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (this.name.length < 2) {
        this.errs.name = "Name is too short";
        return false;
      } else if (this.phone_number.length < 10) {
        this.errs.phone_number = "Invalid Phone Number";
        return false;
      } else if (!this.email.length || !this.email.match(rege)) {
        this.errs.email = "Invalid Email";
        return false;
      } else if (!this.service.length) {
        this.errs.service = "Field is required";
        return false;
      } else {
        return true;
      }
    },
    async submit() {
      if (this.validateForm() === true) {
        try {
          await fetch(process.env.VUE_APP_DB_URL + "/contact-us/data.json", {
            method: "POST",
            body: JSON.stringify({ ...this.payload, created_at: new Date() }),
          })
            .then((res) => res.json())
            .then(async (response) => {
              // console.log(response);
              var body = {
                form: "contact",
                payload: {
                  user_name: this.payload.name,
                  date: this.today,
                  phone: this.payload.phone_number,
                  email: this.payload.email,
                  org: this.payload.organization,
                  role: this.payload.role,
                  service: this.payload.service,
                  desc: this.payload.description,
                },
              };
              await this.$store.dispatch("sendMail", body);
              this.success();
            })
            .catch((err) => {
              alert("something went wrong, please try again");
            });
        } catch (error) {
          alert("something went wrong, please try again");
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
</style>
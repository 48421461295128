<template>
  <form class="w-full *:text-white">
    <!-- <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      placeholder="Name"
      v-model="name"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.name }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      placeholder="Phone number"
      v-model="phone_number"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.phone_number }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="email"
      placeholder="Email address"
      v-model="email"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.email }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      placeholder="What colour do you want?"
      v-model="colour"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.colour }}</p>
    <select
      class="w-full bg-[#303030] mt-4 py-4 px-5 rounded-lg"
      v-model="need_financing"
    >
      <option value="" disabled selected>Do you need financing?</option>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
    <p class="mt-1 !text-red text-xs">{{ errs.need_financing }}</p>
    <textarea
      name=""
      id=""
      class="w-full resize-none bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      cols="30"
      rows="4"
      placeholder="Notes"
      v-model="notes"
    ></textarea>
    <p class="mt-1 !text-red text-xs">{{ errs.notes }}</p>
    <button class="red-btn mt-4 w-full" @click.prevent="submit">
      Order Now
    </button> -->
  </form>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      phone_number: "",
      email: "",
      colour: "",
      need_financing: "",
      notes: "",
      errs: {
        name: "",
        phone_number: "",
        email: "",
        colour: "",
        need_financing: "",
        notes: "",
      },
    };
  },
  computed: {
    selectedVehicle() {
      return this.$store.state.selectedVehicle;
    },
    today() {
      // Get today's date
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const dd = String(today.getDate()).padStart(2, "0");

      // Format the date as YYYY-MM-DD
      const formattedToday = `${yyyy}-${mm}-${dd}`;
      return formattedToday;
    },
    payload() {
      return {
        name: this.name,
        phone_number: this.phone_number,
        email: this.email,
        colour: this.colour,
        need_financing: this.need_financing,
        notes: this.notes,
        vehicle: this.selectedVehicle,
      };
    },
  },
  methods: {
    success() {
      this.$store.dispatch("modalSuccess", {
        title: "Order Submitted",
        top: `Your order for Qoray ${this.selectedVehicle} has been received.`,
        bottom: "A sales rep will reach out to you in 72 hours",
      });
    },
    validateForm() {
      this.errs = {
        name: "",
        phone_number: "",
        email: "",
        colour: "",
        need_financing: "",
        notes: "",
      };
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (this.name.length < 2) {
        this.errs.name = "Name is too short";
        return false;
      } else if (this.phone_number.length < 10) {
        this.errs.phone_number = "Invalid Phone Number";
        return false;
      } else if (!this.email.length || !this.email.match(rege)) {
        this.errs.email = "Invalid Email";
        return false;
      } else if (!this.colour.length) {
        this.errs.colour = "Field is required";
        return false;
      } else if (!this.need_financing.length) {
        this.errs.need_financing = "Field is required";
        return false;
      }
      //  else if (this.notes.length) {
      //   this.errs.need_financing = "Field is required";
      //   return false;
      // }
      else {
        return true;
      }
    },
    async submit() {
      if (this.validateForm() === true) {
        try {
          fetch(process.env.VUE_APP_DB_URL + "/orders/data.json", {
            method: "POST",
            body: JSON.stringify({ ...this.payload, created_at: new Date() }),
          })
            .then((res) => res.json())
            .then(async (response) => {
              // console.log(response);
              var body = {
                form: "order",
                payload: {
                  user_name: this.payload.name,
                  vehicle: this.payload.vehicle,
                  date: this.today,
                  phone: this.payload.phone_number,
                  email: this.payload.email,
                  colour: this.payload.colour,
                  need_financinig: this.payload.need_financing,
                  notes: this.payload.notes,
                },
              };
              // await this.$store.dispatch("sendMail", body);
              this.success();
            })
            .catch((err) => {
              alert("something went wrong, please try again");
            });
        } catch (error) {
          alert("something went wrong, please try again");
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
</style>
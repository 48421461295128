<template>
  <form class="w-full *:text-white">
    <!-- <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      placeholder="Name"
    />
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      placeholder="Phone number"
    />
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="email"
      placeholder="Email address"
    />
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      placeholder="Organization (Optional)"
    />
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      type="text"
      placeholder="Address"
    />
    <textarea
      name=""
      id=""
      class="w-full resize-none bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      cols="30"
      rows="4"
      placeholder="Comment"
    ></textarea>
    <button class="red-btn w-full mt-4">Apply Now</button> -->
  </form>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>
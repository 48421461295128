<template>
  <form class="w-full *:text-white">
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      placeholder="Name"
      type="text"
      v-model="name"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.name }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      placeholder="Email address"
      type="text"
      v-model="email"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.email }}</p>
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-4 py-4 px-5 rounded-lg"
      placeholder="Phone number"
      type="text"
      v-model="phone_number"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.phone_number }}</p>
    <label for="" class="mt-4 inline-block !text-[#9CA3AF]"
      >Preferred Date</label
    >
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-2 py-4 px-5 rounded-lg"
      placeholder="Preferred Date"
      :min="today"
      type="date"
      v-model="date"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.date }}</p>
    <label for="" class="mt-4 inline-block !text-[#9CA3AF]"
      >Preferred Time</label
    >
    <input
      class="w-full bg-[#303030] placeholder:text-[#9CA3AF] mt-2 py-4 px-5 rounded-lg"
      placeholder="Preferred Time"
      type="time"
      v-model="time"
    />
    <p class="mt-1 !text-red text-xs">{{ errs.time }}</p>

    <button class="red-btn w-full mt-4" @click.prevent="submit">
      Schedule Now
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      phone_number: "",
      email: "",
      date: "",
      time: "",
      errs: {
        name: "",
        phone_number: "",
        email: "",
        date: "",
        time: "",
      },
      // notes: "",
    };
  },
  computed: {
    today() {
      // Get today's date
      const today = new Date();
      today.setDate(today.getDate() + 1);
      const yyyy = today.getFullYear();
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const dd = String(today.getDate()).padStart(2, "0");

      // Format the date as YYYY-MM-DD
      const formattedToday = `${yyyy}-${mm}-${dd}`;
      return formattedToday;
    },
    payload() {
      return {
        name: this.name,
        phone_number: this.phone_number,
        email: this.email,
        date: this.date,
        time: this.time,
      };
    },
  },
  methods: {
    success() {
      this.$store.dispatch("modalSuccess", {
        title: "Request Submitted",
        top: `Your submission has been received.`,
        bottom: "A sales rep will reach out to you in 72 hours",
      });
    },
    validateForm() {
      this.errs = {
        name: "",
        phone_number: "",
        email: "",
        date: "",
        time: "",
      };
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (this.name.length < 2) {
        this.errs.name = "Name is too short";
        return false;
      } else if (!this.email.length || !this.email.match(rege)) {
        this.errs.email = "Invalid Email";
        return false;
      } else if (this.phone_number.length < 10) {
        this.errs.phone_number = "Invalid Phone Number";
        return false;
      } else if (
        !this.date.length ||
        new Date(this.date) < new Date(this.today)
      ) {
        this.errs.date = "Date must be tomorrow upwards";
        return false;
      } else if (!this.time.length) {
        this.errs.time = "Field is required";
        return false;
      } else {
        return true;
      }
    },
    async submit() {
      if (this.validateForm() === true) {
        try {
          fetch(process.env.VUE_APP_DB_URL + "/schedules/data.json", {
            method: "POST",
            body: JSON.stringify({ ...this.payload, created_at: new Date() }),
          })
            .then((res) => res.json())
            .then(async (response) => {
              // console.log(response);
              var body = {
                form: "charge",
                payload: {
                  user_name: this.payload.name,
                  phone: this.payload.phone_number,
                  email: this.payload.email,
                  date: this.payload.date,
                  time: this.payload.time,
                },
              };
              await this.$store.dispatch("sendMail", body);
              this.success();
            })
            .catch((err) => {
              alert("something went wrong, please try again");
            });
        } catch (error) {
          alert("something went wrong, please try again");
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    id="app"
    ref="topOfPage"
    class="relative"
    :class="pos === 'relMob' && mobile ? 'bg-realBlack' : ''"
  >
    <!-- <div class="w-full"> -->
    <!-- <div
        class="w-full abss absolute min-h-screen max-h-screen flex items-center justify-center top-0 left-0 px-8"
      >
        <img src="@/assets/svgs/logo.svg" class="w-full animate-pulse" alt="" />
      </div> -->
    <!-- <div class="beg w-full"> -->
    <Nav
      v-if="!noFoot"
      :bg="bg"
      :nbg="nbg"
      :pos="pos"
      :logo="logo"
      :blur="blur"
    />
    <router-view />
    <Footer v-if="!noNav" />
    <!-- </div> -->
    <!-- </div> -->
    <Modal />
    <SuccessModal />
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Modal from "./components/Modal.vue";
import Nav from "./components/Nav.vue";
import SuccessModal from "./components/SuccessModal.vue";
import gsap from "gsap";
export default {
  components: { Nav, Footer, Modal, SuccessModal },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    thisRout() {
      return this.$route.name;
    },
    bg() {
      return this.$route.meta.bg;
    },
    nbg() {
      return this.$route.meta.nbg;
    },
    logo() {
      return this.$route.meta.logo;
    },
    pos() {
      return this.$route.meta.pos;
    },
    blur() {
      return this.$route.meta.blur;
    },
    noNav() {
      return this.$route.meta.noNav;
    },
    noFoot() {
      return this.$route.meta.noFoot;
    },
    mobile() {
      return window.innerWidth <= 768;
    },
  },
  async mounted() {
    // gsap.set(".beg", { yPercent: 100 });
    await fetch(process.env.VUE_APP_API_URL + "/prices", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        this.$store.commit("SetPrices", response.data);
        // console.log(this.$store.state.prices);
        // gsap.to(".beg", { yPercent: 0, duration: 1, ease: "power4.out" });
      })
      .catch((err) => {
        console.log(err);
      });
    // var load = {
    //   form: "newsletter",
    //   payload: { name: "newsletter" },
    // };
    // this.$store.dispatch("sendMail", load);
  },
};
</script>

<style lang="scss">
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  @apply bg-realBlack rounded-md;
}
*::-webkit-scrollbar-thumb {
  // border: 3px solid;
  @apply bg-[#303030]  rounded-md cursor-pointer;
}
// /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
.zoom {
  animation: zoom 2.5s linear infinite;
}
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
  /* 100% {
    transform: scale(0.6);
  } */
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
// nav {
//   transition: all 0.8s ease-out;
// }
.contain {
  @apply w-[90%] max-w-xl m-auto;
}
.trans-btn {
  @apply text-base text-white px-6 py-4 bg-[transparent] rounded-lg  relative overflow-hidden hover:bg-[transparent] hover:text-red z-[1] border border-black;
  font-weight: 400;
  &.tiny {
    @apply py-2 text-xs font-normal min-w-40 tracking-[1.12px] rounded-[0.25rem];
  }
  &.upper {
    @apply uppercase;
  }
  /* font-size: 0.8rem; */
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fff;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-[transparent] absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(73);
    }
  }
}
.uni-btn {
  @apply text-xs md:text-sm lg:text-base text-[#000] px-4 lg:px-6 py-2 lg:py-4 bg-white relative overflow-hidden hover:bg-[transparent] hover:text-white z-[1] border border-[#000] font-bold;
  // font-weight: 400;
  &.tiny {
    @apply py-2 text-xs font-bold min-w-40;
  }
  &.upper {
    @apply uppercase;
  }
  /* font-size: 0.8rem; */
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #000;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-white absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(73);
    }
  }
}
.sec-btn {
  @apply text-xs md:text-sm lg:text-base text-white px-4 lg:px-6 py-2 lg:py-4 bg-secondaryBtn rounded-md lg:rounded-lg  relative overflow-hidden hover:bg-[transparent] hover:text-red z-[1] border border-black;
  font-weight: 400;
  &.tiny {
    @apply py-2 text-xs font-normal min-w-40 tracking-[1.12px] rounded-[0.25rem];
  }
  &.upper {
    @apply uppercase;
  }
  /* font-size: 0.8rem; */
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fff;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-secondaryBtn absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(73);
    }
  }
}
.red-btn {
  @apply text-xs md:text-sm lg:text-base text-white px-4 lg:px-6 py-2 lg:py-4 bg-red  rounded-md lg:rounded-lg  relative overflow-hidden hover:bg-[transparent] hover:text-red z-[1] border border-red;
  &.tiny {
    @apply py-2 text-xs font-normal min-w-40 tracking-[1.12px] rounded-[0.25rem];
  }
  &.upper {
    @apply uppercase;
  }
  font-weight: 400;
  /* font-size: 0.8rem; */
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fff;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-red absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(73);
    }
  }
}
.black-btn {
  @apply text-xs md:text-sm lg:text-base text-white px-8 lg:px-8 py-2 lg:py-2 bg-realBlack  rounded-[5px]  relative overflow-hidden hover:bg-[transparent] hover:text-realBlack z-[1] border border-realBlack;
  &.long {
    @apply md:min-w-[300px] min-w-0 md:w-auto;
  }
  // &.mdlong {
  //   @apply md:min-w-[300px];
  // }
  &.tiny {
    @apply py-2 text-xs font-normal min-w-40 tracking-[1.12px] rounded-[0.25rem];
  }
  &.upper {
    @apply uppercase;
  }
  font-weight: 400;
  /* font-size: 0.8rem; */
  box-shadow: -4px 4px 25px 0px #4f4f5126;
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fff;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-realBlack absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(200);
    }
  }
}
.white-btn {
  @apply text-xs md:text-sm lg:text-base text-black px-8 lg:px-8 py-2 lg:py-2 bg-white  rounded-[5px]  relative overflow-hidden hover:bg-[transparent] hover:text-white z-[1] border border-white;
  &.long {
    @apply md:min-w-[300px] min-w-0 md:w-auto;
  }
  &.tiny {
    @apply py-2 text-xs font-normal min-w-40 tracking-[1.12px] rounded-[0.25rem];
  }
  &.upper {
    @apply uppercase;
  }
  &.black-border {
    @apply border border-realBlack;
  }
  font-weight: 400;
  /* font-size: 0.8rem; */
  box-shadow: -4px 4px 25px 0px #4f4f5126;
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #101010;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-white absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(200);
    }
  }
}

.outline-btn {
  @apply text-base text-[#FAF0E6] px-6 py-4 bg-[transparent] border border-[#FAF0E6] hover:border-none rounded-lg  relative overflow-hidden hover:bg-[#FAF0E6] hover:text-[#FAF0E6] z-[1];
  &.tiny {
    @apply py-2 text-xs font-normal min-w-40 tracking-[1.12px] rounded-[0.25rem];
  }
  &.upper {
    @apply uppercase;
  }
  font-weight: 400;
  /* font-size: 0.8rem; */
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #dc2318;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-[transparent] absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(73);
    }
  }
}
</style>

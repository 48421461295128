<template>
  <div class="w-full">
    <!-- PRELOAD -->

    <!-- v-else -->
    <img
      src="@/assets/images/sable/hero.png"
      class="fixed w-px h-px opacity-0 -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
    />
    <img
      class="fixed w-px h-px opacity-0 -z-10"
      v-for="(col, index) in spectrum"
      :key="index"
      :src="col.car"
      alt=""
    />
    <!-- PRELOAD -->
    <div
      v-if="!lhero"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <div class="w-full">
      <section
        class="relative w-full heroBG md:min-h-0 min-h-screen md:max-h-none max-h-screen"
      >
        <img
          src="@/assets/images/sable/hero.png"
          class="w-full md:block hidden"
          alt=""
        />
        <div class="absolute top-0 right-0 left-0">
          <div
            class="min-h-screen max-h-none md:w-[90%] w-[95%] max-w-xl mx-auto h-full flex flex-col items-start justify-between md:pt-[20vh] md:pl-10 pt-[15vh] pb-[10vh]"
          >
            <!-- <div class="hidden w-full md:block"></div> -->
            <div class="w-full">
              <h1 class="font-belgiano md:text-[80px] text-[36px]">
                Sable<span class="text-green">.</span>
              </h1>
              <p class="text-xs font-inter md:text-2xl">
                Starting from ₦{{ prcs.sable }} million
              </p>
              <div class="flex items-center gap-6 mt-3">
                <p class="text-xs font-inter md:text-lg">10% down payment</p>
                <div class="w-px h-4 bg-realBlack"></div>
                <p class="text-xs font-inter md:text-lg">Spread over 5 years</p>
              </div>
              <router-link :to="{ name: 'order-sable' }">
                <button
                  class="black-btn w-[clamp(9.375rem,5.869vw+7.174rem,15.625rem)] mt-7"
                >
                  Order Now
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack">
        <div
          class="flex flex-wrap justify-between items-center md:w-[90%] w-[95%] max-w-xl mx-auto py-20 md:gap-4 gap-y-6 md:flex-nowrap"
        >
          <div class="text-center md:w-1/4 w-[50%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">
              Dimension
            </p>
            <p class="mt-1 text-sm font-inter md:text-xl">
              L:4619 x W:1869 x L:1628
            </p>
          </div>
          <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
          <div class="text-center md:w-1/4 w-[45%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">
              Top Speed
            </p>
            <p class="mt-1 text-sm font-inter md:text-xl">150Km/H</p>
          </div>
          <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
          <div class="text-center md:w-1/4 w-[45%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">Range</p>
            <p class="mt-1 text-sm font-inter md:text-xl">480km</p>
          </div>
          <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
          <div class="text-center md:w-1/4 w-[45%] text-white">
            <p class="font-inter text-xs uppercase tracking-[1.25px]">
              Battery
            </p>
            <p class="mt-1 text-sm font-inter md:text-xl">64kWH</p>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-20 py-5 block">
        <div class="w-full overflow-x-auto">
          <img
            src="@/assets/images/sable/interior.png"
            class="hidden md:block w-full"
            alt=""
          />
          <img
            src="@/assets/images/sable/interiorMob.png"
            class="md:hidden block w-full"
            alt=""
          />
        </div>
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="md:w-full w-[95%] mx-auto">
              <div
                class="md:w-[80%] w-full mx-auto max-w-xl flex md:flex-row flex-col items-start md:gap-8 md:py-8 pt-7"
              >
                <h3
                  class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:w-2/12 w-full"
                >
                  Future Forward
                </h3>
                <p
                  class="md:text-base text-xs tracking-[1.8px] text-white md:w-10/12 w-full mt-3 md:mt-0"
                >
                  Step into the future of driving with our electric vehicle,
                  where innovative design meets sustainable energy. Our vehicle
                  boasts an intelligent cockpit, designed to provide a seamless
                  driving experience. Enjoy a comfortable and spacious interior,
                  perfect for long road trips or daily commutes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-20 py-5 block">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="w-full grid grid-cols-2 gap-4">
              <div class="col-span-2 row-span-2">
                <img
                  src="@/assets/images/sable/cockpit.png"
                  class="w-full"
                  alt=""
                />
              </div>
              <div>
                <img
                  src="@/assets/images/sable/passengerView.png"
                  alt=""
                  class="w-full"
                />
              </div>
              <div>
                <img
                  src="@/assets/images/sable/dashboard.png"
                  alt=""
                  class="w-full"
                />
              </div>
            </div>
            <!-- <div class="xl:w-[90%] lg:w-[95%] mx-auto md:pt-0 pt-8">
              <div class="md:w-full w-[95%] mx-auto "></div>
            </div> -->
            <div class="relative md:w-1/2 mt-12 w-[95%] mx-auto md:ml-0">
              <div class="">
                <div class="relative left-0 right-0 w-full text-white md:pl-8">
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
                  >
                    Command Center at Your Fingertips
                  </h3>
                  <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                    Stay connected and in control with our class-leading 15.6”
                    Hi-res touch screen. Enjoy a bird's eye view of your
                    surroundings with the 360 Around View Monitor and Rear
                    Parking Camera, making parking and maneuvering a breeze. Our
                    intuitive infotainment system keeps you entertained,
                    informed, and safe on the road
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-20 pt-11 pb-9">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="w-full">
              <img
                src="@/assets/images/sable/topView.png"
                alt=""
                class="w-full"
              />
            </div>
            <div class="relative md:w-1/2 mt-12 w-[95%] mx-auto md:ml-0">
              <div class="">
                <div class="relative left-0 right-0 w-full text-white md:pl-8">
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
                  >
                    Space for Every Adventure
                  </h3>
                  <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                    Whether you're packing for a road trip or just need some
                    extra room for groceries, our electric car has got you
                    covered. With a generous trunk capacity of 508L, expandable
                    up to 1388L when the rear seats are folded down, you'll have
                    all the space you need to bring along whatever life
                    requires.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack md:py-20 pt-11 pb-9">
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="w-full">
              <img
                src="@/assets/images/sable/topFrontView.png"
                alt=""
                class="w-full"
              />
            </div>
            <div class="relative md:w-1/2 mt-12 w-[95%] mx-auto md:ml-0">
              <div class="">
                <div class="relative left-0 right-0 w-full text-white md:pl-8">
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
                  >
                    Shine Bright, Drive Bold
                  </h3>
                  <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                    Make a statement on the road with our electric car's sleek
                    design features. The Through-LED daytime running lights and
                    LED brake light ensure you're visible and stylish, while the
                    sporty rear spoiler adds a touch of dynamism to your drive.
                    Wherever you go, arrive with confidence and style.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full relative bg-realBlack py-20">
        <img
          class="w-full hidden md:block"
          src="@/assets/images/sable/speedView.png"
          alt=""
        />
        <img
          class="w-full md:hidden block"
          src="@/assets/images/sable/speedViewMob.png"
          alt=""
        />
        <div class="w-full max-w-[1550px] mx-auto">
          <div class="md:w-[95%] w-full mx-auto">
            <div class="relative md:w-1/2 mt-12 w-[95%] mx-auto md:ml-0">
              <div class="">
                <div class="relative left-0 right-0 w-full text-white md:pl-8">
                  <h3
                    class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
                  >
                    Wild Beauty, Electric Soul
                  </h3>
                  <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                    Meet Sable, the electric car inspired by the majestic
                    marten. With its agile performance, sleek design, and
                    shimmering finish, Sable embodies the spirit of its wild
                    namesake. Experience the fusion of innovation and nature's
                    beauty
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full bg-realBlack">
        <div class="xl:w-[90%] lg:w-[95%] mx-auto md:pt-0 pt-8">
          <div class="md:w-full w-[95%] mx-auto md:pl-8">
            <h2
              class="capitalize font-belgiano text-[1rem] md:text-[56px] text-white"
            >
              Go The Distance
            </h2>
          </div>
          <img
            src="@/assets/images/sable/distance.png"
            alt=""
            class="w-full mt-4 md:mt-8"
          />
          <div class="md:w-full w-[95%] mx-auto md:pl-8 pb-10">
            <p
              class="md:text-base text-white text-xs tracking-[1.8px] md:mt-9 mt-4 md:w-2/3 w-full"
            >
              With a single charge, it can travel up to 480 km, effortlessly
              covering long routes like Ondo to Enugu, (384 km) and still having
              plenty of juice left to spare. Experience the freedom to go
              further, without worrying about running out of charge.
            </p>
          </div>
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pt-[120px] pt-9">
        <div class="w-full max-w-xl md:pl-8">
          <h2 class="capitalize font-belgiano text-[1rem] md:text-[56px]">
            View Our Spectrum
          </h2>
        </div>
      </section>
      <section class="relative w-full overflow-hidden" ref="swipeArea">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-[-1]"
        >
          <!-- <img
            src="@/assets/svgs/greyLogo.svg"
            class="w-full opacity-5"
            alt="Qoray"
          /> -->
          <h1
            class="w-full text-newGrey md:text-9xl text-7xl scale-125 font-belgiano font-black text-center md:scale-[2.5] opacity-5"
          >
            SABLE
          </h1>
        </div>
        <div
          class="md:w-full w-[95%] mx-auto flex items-center justify-center mt-20 switchCont"
        >
          <img
            :src="spectrum[activeSpectrum].car"
            class="w-full mx-auto md:w-1/2"
            alt=""
          />
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pb-[120px] pb-9">
        <div class="w-[80%] mx-auto max-w-xl mt-9">
          <div class="flex items-center justify-center w-full">
            <div>
              <p class="text-sm text-newGrey opacity-65">Available colors</p>
              <div class="flex items-center w-full gap-3 mt-1">
                <img
                  class="w-10 h-10 overflow-hidden rounded-full cursor-pointer"
                  :class="[
                    activeSpectrum === index ? 'border-[3px] border-green' : '',
                  ]"
                  v-for="(col, index) in spectrum"
                  :key="index"
                  :src="col.icon"
                  @click="changeCarTo(index)"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="w-full">
        <div class="w-[95%] max-w-[1550px] mx-auto py-[60px]">
          <div class="md:w-[80%] w-full mx-auto max-w-xl">
            <div class="w-full">
              <div class="w-full">
                <h6 class="text-lg font-belgiano md:text-2xl mb-2">Specs</h6>
                <div
                  class="flex md:grid md:grid-cols-3 items-start flex-wrap w-full justify-between gap-y-4 md:gap-8"
                >
                  <div
                    v-for="spec in showAll ? specs : specs.slice(0, 6)"
                    :key="spec.title"
                    class="w-fit md:min-w-fit min-w-[50%] px-2 py-1 md:px-5 md:py-4"
                  >
                    <p class="w-full text-sm whitespace-nowrap">
                      {{ spec.title }}
                    </p>
                    <p class="w-full whitespace-nowrap">
                      <!-- <br /> -->
                      {{ spec.value }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full" v-if="showAll">
                <div
                  class="w-full mt-8"
                  v-for="other in otherSpecs"
                  :key="other.title"
                >
                  <h6 class="text-lg font-belgiano md:text-2xl mb-2">
                    {{ other.title }}
                  </h6>
                  <div
                    class="flex flex-wrap items-start w-full gap-4 pl-2 mt-2 md:px-7 md:pl-0 md:gap-8"
                  >
                    <div
                      class="vehSpec"
                      v-for="otherValue in other.values"
                      :key="otherValue"
                    >
                      <span class="bigDot"></span>
                      <p>{{ otherValue }}</p>
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="px-2 py-4 mt-3">
                    <p class="text-lg">
                      <span class="text-sm">Warranty</span>
                      <br />
                      36 Months
                    </p>
                  </div>
                  <div class="w-full mt-3">
                    <div
                      class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10"
                    >
                      <p class="font-inter leading-[26px] text-green">
                        Home charging available
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full">
                <button
                  v-if="!showAll"
                  @click="showAll = true"
                  class="black-btn long mt-7"
                >
                  See More
                </button>
                <div class="flex items-center justify-between w-full" v-else>
                  <router-link :to="{ name: 'order-sable' }">
                    <button class="black-btn long mt-7">Order Now</button>
                  </router-link>
                  <button
                    class="white-btn long mt-7 black-border"
                    @click="showAll = false"
                  >
                    See Less
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import col1 from "@/assets/images/sable/colors/black.png";
import col2 from "@/assets/images/sable/colors/brown.png";
import col3 from "@/assets/images/sable/colors/blue.png";
import col4 from "@/assets/images/sable/colors/grey.png";
import col5 from "@/assets/images/sable/colors/white.png";
import car1 from "@/assets/images/sable/colors/black_cropped.png";
import car2 from "@/assets/images/sable/colors/brown_cropped.png";
import car3 from "@/assets/images/sable/colors/blue_cropped.png";
import car4 from "@/assets/images/sable/colors/grey_cropped.png";
import car5 from "@/assets/images/sable/colors/white_cropped.png";
export default {
  data() {
    return {
      lhero: false,
      showAll: false,
      swipe: {
        touchstartX: 0,
        touchendX: 0,
      },
      specs: [
        { title: "Charging", value: "AC/DC" },
        { title: "Length(mm)", value: "4619 " },
        { title: "Width(mm)", value: "1869" },
        { title: "Height (mm)", value: "1628" },
        { title: "Wheelbase(mm)", value: "2770" },
        { title: "Battery Capacity", value: "62kWh" },
        { title: "Driving Range", value: "480km" },
        { title: "Max.Torque(Nm)", value: "210" },
        { title: "Drive Layout", value: "Front Wheel Drive" },
        {
          title: "Brake system",
          value: "Front Ventilated Disc/Rear Solid Disc",
        },
        {
          title: "Suspension",
          value: "Front MacPherson Struts/Rear Multi-Link",
        },
        { title: "Driving Modes", value: "Sport, Comfort, Eco" },
        { title: "Steering Assists Modes", value: "Sport, Standard" },
        { title: "Seat Materials", value: "Synthetic Leather " },
        {
          title: "Power Adjustable Front Row Seats",
          value: "6 - Way Adustment",
        },
        {
          title: "Air Conditioning System",
          value: "Automatic Climate Control",
        },
        { title: "Voice Control", value: "English" },
        { title: "USB Socket", value: "4" },
        { title: "Air Filtration System", value: "HEPA N95 Filter N95 " },
        { title: "Tyre specifications", value: "225/60R18" },
        { title: "Motor Type", value: "Permanent Magnet Synchronous" },
      ],
      otherSpecs: [
        {
          title: "Safety",
          values: [
            "Forward Collision Warning (FCW)",
            "Lane Keeping Assist System (LKA) ",
            "Lane Departure Warning (LDW)",
            "Blind Spot Detection (BSD) ",
            "Active Emergency Braking (AEB)",
            "Traffic Jam Assist(TJA)",
            "Adaptive High beam Front Light",
            "Traffic Sign Recognition (TSR)",
            "Adaptive Cruise Control System",
            "Rear Object Passing Warning System",
            "Door Opening Warning System",
          ],
        },
        {
          title: "Exterior",
          values: [
            "LED Head lamps & LED Rear Fog Light",
            "LED Day Time Running Light",
            "LED Rear Combination Light",
          ],
        },
        {
          title: "Comfort & Convenience",
          values: [
            "Panoramic Sunroof with Electric Power Sunshadeade",
            "Driver's Seat Memory and Welcoming Function",
            "Synthetic Leather Steering Wheel",
            "Steering Wheel with Audio System Control",
            "Rear Air Conditioning Outlet",
            "Power Central Door Locks with Remote Control",
            "Mobile Wireless Charger",
            "12V Accessory PowerSocket 12V",
            "Power Tailgate",
            "Electronic Parking Brake",
          ],
        },
      ],
      spectrum: [
        {
          icon: col1,
          car: car1,
        },
        {
          icon: col2,
          car: car2,
        },
        {
          icon: col3,
          car: car3,
        },
        {
          icon: col4,
          car: car4,
        },
        {
          icon: col5,
          car: car5,
        },
      ],
      activeSpectrum: 0,
    };
  },
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
    prcs() {
      return this.$store.getters.dispPrices;
    },
  },
  methods: {
    handleSwipe() {
      if (this.swipe.touchendX < this.swipe.touchstartX) {
        if (this.activeSpectrum < this.spectrum.length - 1) {
          this.changeCarTo(this.activeSpectrum + 1);
        }
      }
      if (this.swipe.touchendX > this.swipe.touchstartX) {
        if (this.activeSpectrum > 0) {
          this.changeCarTo(this.activeSpectrum - 1, true);
        }
      }
    },
    async changeCarTo(index, reverse = false) {
      const changeTo = async (index) => {
        this.activeSpectrum = index;
      };
      if (reverse) {
        if (this.activeSpectrum !== 0) {
          gsap.to(".switchCont", {
            xPercent: 110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: -110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      } else {
        if (this.activeSpectrum !== index) {
          gsap.to(".switchCont", {
            xPercent: -110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: 110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      }
    },
  },
  watch: {
    lhero() {
      if (this.lhero === true) {
        this.$refs.swipeArea.addEventListener("touchstart", (e) => {
          this.swipe.touchstartX = e.changedTouches[0].screenX;
        });
        this.$refs.swipeArea.addEventListener("touchend", (e) => {
          this.swipe.touchendX = e.changedTouches[0].screenX;
          this.handleSwipe();
        });
      }
    },
  },
};
</script>

<style scoped >
.heroBG {
  background-image: url("@/assets/images/sable/hero.png");
  background-position: top right;
  background-size: cover;
}
@media (max-width: 768px) {
  .heroBG {
    background-position: 35% bottom;
    background-image: url("@/assets/images/sable/hero.png");
  }
}
.specBack {
  background-image: url("@/assets/svgs/greyLogo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.vehSpec {
  @apply flex items-center gap-3 md:w-auto w-full;
}
.bigDot {
  @apply inline-block w-2 h-2 rounded-full bg-green bg-opacity-65;
}
</style>

<template>
  <div class="w-full">
    <img
      src="@/assets/images/sill/hero.png"
      class="fixed w-px h-px opacity-0 -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
    />
    <div
      v-if="!lhero"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>
    <section class="w-full heroBg min-h-screen relative">
      <div class="ovl absolute bottom-0 left-0 right-0 h-[12vh]"></div>
      <div class="w-[90%] max-w-xl mx-auto pt-[20vh]">
        <div class="w-full md:pl-12 pl-6">
          <h1 class="font-belgiano md:text-[80px] text-[36px]">
            Sill<span class="text-green">.</span>
          </h1>
          <p class="text-xs font-inter md:text-2xl">
            Starting from ₦{{ prcs.sill }} million
          </p>
          <div class="flex items-center gap-6 mt-3">
            <p class="text-xs font-inter md:text-lg">10% down payment</p>
            <div class="w-px h-4 bg-realBlack"></div>
            <p class="text-xs font-inter md:text-lg">Spread over 5 years</p>
          </div>
          <router-link :to="{ name: 'order-sill' }">
            <button
              class="black-btn w-[clamp(9.375rem,5.869vw+7.174rem,15.625rem)] mt-7"
            >
              Order Now
            </button>
          </router-link>
        </div>
      </div>
    </section>
    <section class="w-full bg-realBlack">
      <div
        class="flex flex-wrap justify-between items-center md:w-[90%] w-[95%] max-w-xl mx-auto py-20 md:gap-4 gap-y-6 md:flex-nowrap"
      >
        <div class="text-center md:w-1/4 w-[50%] text-white">
          <p class="font-inter text-xs uppercase tracking-[1.25px]">
            Dimension
          </p>
          <p class="mt-1 text-sm font-inter md:text-xl">
            L:4980x W:1980 x H:1450
          </p>
        </div>
        <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
        <div class="text-center md:w-1/4 w-[45%] text-white">
          <p class="font-inter text-xs uppercase tracking-[1.25px]">
            Top Speed
          </p>
          <p class="mt-1 text-sm font-inter md:text-xl">185km/H</p>
        </div>
        <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
        <div class="text-center md:w-1/4 w-[45%] text-white">
          <p class="font-inter text-xs uppercase tracking-[1.25px]">Range</p>
          <p class="mt-1 text-sm font-inter md:text-xl">520km</p>
        </div>
        <div class="w-px h-4 md:block hidden bg-white bg-opacity-40"></div>
        <div class="text-center md:w-1/4 w-[45%] text-white">
          <p class="font-inter text-xs uppercase tracking-[1.25px]">Battery</p>
          <p class="mt-1 text-sm font-inter md:text-xl">64(KWh)</p>
        </div>
      </div>
    </section>
    <section class="w-full">
      <img src="@/assets/images/sill/cockpit.png" class="w-full" alt="" />
    </section>
    <section class="w-full bg-realBlack md:py-[120px] py-20">
      <div class="md:w-[90%] w-[95%] max-w-xl mx-auto">
        <div
          class="md:w-[90%] w-full mx-auto flex flex-col md:flex-row items-stretch md:gap-6 gap-7"
        >
          <img
            src="@/assets/images/sill/passengerView.png"
            class="w-full md:w-3/5"
            alt=""
          />
          <div class="relative w-full md:w-2/5 tb-grad">
            <div class="md:mt-[50%]">
              <div class="relative w-full text-white md:px-4">
                <h3
                  class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                >
                  Technology Meets Transportation
                </h3>
                <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                  Sill surrounds you with cutting-edge tech, featuring a 49"
                  AR-HUD, 17.6" central screen, and 12.3" passenger screen,
                  seamlessly connecting you to your journey, entertainment, and
                  surroundings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full bg-realBlack md:py-[120px] pb-20">
      <div class="md:w-[90%] w-[95%] max-w-xl mx-auto">
        <div
          class="md:w-[90%] w-full mx-auto flex flex-col-reverse md:flex-row items-stretch md:gap-6 gap-7"
        >
          <div class="relative w-full md:w-2/5 tb-grad">
            <div class="md:mt-[50%]">
              <div class="relative w-full text-white md:px-4">
                <h3
                  class="capitalize font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] md:mt-4"
                >
                  Sit Back, Relax, Accelerate
                </h3>
                <p class="tracking-[1.8px] mt-3 md:text-base text-xs">
                  Our electric car's integrated sports seats embrace you in
                  comfort and style, providing exceptional support and luxury
                  for every drive, whether you're carving through corners or
                  cruising down the highway.
                </p>
              </div>
            </div>
          </div>
          <img
            src="@/assets/images/sill/backSeatView.png"
            class="w-full md:w-3/5"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="w-full relative bg-realBlack">
      <div class="w-full relative">
        <img class="w-full" src="@/assets/images/sill/cityView.png" alt="" />
        <div class="w-full absolute bottom-0 ovl left-0 right-0 h-[20vh]"></div>
      </div>
      <div class="md:absolute md:bottom-20 md:left-0 w-full">
        <div class="md:w-[90%] w-[95%] max-w-xl mx-auto">
          <div class="md:w-5/12 w-full md:py-0 pt-8 pb-11">
            <h3
              class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
            >
              Sill: Where Form and Function Unite
            </h3>
            <p class="md:text-base text-xs tracking-[1.8px] mt-3 text-white">
              Sill's suspension bridge-inspired design commands attention on the
              road, its spacious and agile dimensions (4980mm x 1980mm x 2980mm)
              delivering a perfect blend of style and performance.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full bg-white py-20">
      <div class="md:w-[90%] w-[95%] max-w-xl mx-auto">
        <img
          src="@/assets/images/sill/openDoors.png"
          alt=""
          class="mx-auto "
        />
        <!-- <img
          src="@/assets/images/sill/blueTrans.png"
          alt=""
          class="mx-auto md:hidden block w-full"
        /> -->
        <div class="md:w-5/12 mx-auto w-full mt-8">
          <h3
            class="capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
          >
            Wing Door Models
          </h3>
          <p class="md:text-base text-xs tracking-[1.8px] mt-3 text-realBlack">
            Experience the future of electric mobility with our cutting-edge
            vehicles, featuring iconic wing doors on select models and
            head-turning styles across the range.
          </p>
        </div>
      </div>
    </section>
    <section class="w-full relative">
      <h3
        class="md:hidden w-[95%] mx-auto block capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px] mb-3"
      >
        Unleash the Power Within
      </h3>
      <div class="w-full relative">
        <img src="@/assets/images/sill/speedView.png" class="w-full" alt="" />
        <div class="w-full absolute top-0 left-0 right-0">
          <div class="md:w-[90%] w-[95%] max-w-xl mx-auto md:pt-20">
            <div class="md:w-5/12 w-full">
              <h3
                class="hidden md:block capitalize text-realBlack font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
              >
                Unleash the Power Within
              </h3>
              <p
                class="md:text-base text-xs tracking-[1.8px] md:mt-3 text-realBlack"
              >
                Our electric car's high-performance chassis features a
                double-wishbone front and five-link rear suspension, with a low
                470mm COG and 50:50 weight distribution for exceptional handling
                and stability.
              </p>
            </div>
          </div>
        </div>
        <div
          class="w-full hidden md:block absolute bottom-0 ovl left-0 right-0 h-[20vh]"
        ></div>
      </div>
    </section>
    <section class="w-full relative bg-realBlack">
      <div class="w-full relative md:pt-0 pt-10">
        <div
          class="w-full absolute top-0 hidden md:block ovlTop left-0 right-0 h-[20vh]"
        ></div>
        <img
          class="w-full"
          src="@/assets/images/sill/speedViewBlack.png"
          alt=""
        />
        <div class="w-full absolute bottom-0 ovl left-0 right-0 h-[20vh]"></div>
      </div>
      <div class="md:absolute md:bottom-20 md:left-0 w-full">
        <div class="md:w-[90%] w-[95%] max-w-xl mx-auto">
          <div class="md:w-5/12 w-full md:py-0 pt-8 pb-11">
            <h3
              class="capitalize text-white font-belgiano md:text-[26px] text-[1rem] tracking-[1.4px]"
            >
              A Totally Sharp Look
            </h3>
            <p class="md:text-base text-xs tracking-[1.8px] mt-3 text-white">
              Illuminate the road with our Sill sharp look, featuring 275 light
              beads, 3D through-tail lights, and ultra-thin daytime running
              lights. A dazzling design that's sure to turn heads.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-realBlack">
      <div class="w-[95%] max-w-[1550px] mx-auto md:pt-20 md:pb-20 pb-10 pt-8">
        <div class="w-full md:pl-8 max-w-xl mx-auto">
          <h2
            class="capitalize text-white font-belgiano text-[1rem] md:text-[56px]"
          >
            Go The Distance
          </h2>
        </div>
        <img
          src="@/assets/images/sill/distance.png"
          alt=""
          class="w-full mt-4 md:mt-8"
        />
        <div class="w-full max-w-xl mx-auto md:pl-8 md:mt-14 mt-6">
          <p
            class="md:text-base text-white text-xs tracking-[1.8px] md:mt-9 md:w-2/3 w-full"
          >
            With a single charge, it can travel up to 520 km, effortlessly
            covering long routes like Lagos to Warri (428 km) and still having
            plenty of juice left to spare. Experience the freedom to go further,
            without worrying about running out of charge.
          </p>
        </div>
      </div>
    </section>
    <section class="w-full hidden">
      <section class="w-[95%] max-w-[1550px] mx-auto md:pt-[120px] pt-9">
        <div class="w-full max-w-xl md:pl-8">
          <h2 class="capitalize font-belgiano text-[1rem] md:text-[56px]">
            View Our Spectrum
          </h2>
        </div>
      </section>
      <section class="relative w-full overflow-hidden" ref="swipeArea">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-[-1]"
        >
          <!-- <img
            src="@/assets/svgs/greyLogo.svg"
            class="w-full opacity-5"
            alt="Qoray"
          /> -->
          <h1
            class="w-full text-newGrey md:text-9xl text-7xl scale-125 font-belgiano font-black text-center md:scale-[2.5] opacity-5"
          >
            SILL
          </h1>
        </div>
        <div
          class="md:w-full w-[95%] mx-auto flex items-center justify-center mt-20 switchCont"
        >
          <img
            :src="spectrum[activeSpectrum].car"
            class="w-full mx-auto md:w-1/2"
            alt=""
          />
        </div>
      </section>
      <section class="w-[95%] max-w-[1550px] mx-auto md:pb-[120px] pb-9">
        <div class="w-[80%] mx-auto max-w-xl mt-9">
          <div class="flex items-center justify-center w-full">
            <div>
              <p class="text-sm text-newGrey opacity-65">Available colors</p>
              <div class="flex items-center w-full gap-3 mt-1">
                <img
                  class="w-10 h-10 overflow-hidden rounded-full cursor-pointer"
                  :class="[
                    activeSpectrum === index ? 'border-[3px] border-green' : '',
                  ]"
                  v-for="(col, index) in spectrum"
                  :key="index"
                  :src="col.icon"
                  @click="changeCarTo(index)"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section class="w-[95%] max-w-[1550px] mx-auto py-[60px]">
      <div class="md:w-[80%] w-full mx-auto max-w-xl">
        <div class="w-full">
          <div class="w-full">
            <h6 class="text-lg font-belgiano md:text-2xl mb-2">Specs</h6>
            <div
              class="flex md:grid md:grid-cols-3 items-start flex-wrap w-full justify-between gap-y-4 md:gap-8"
            >
              <div
                v-for="spec in showAll ? specs : specs.slice(0, 6)"
                :key="spec.title"
                class="w-fit md:min-w-fit min-w-[50%] px-2 py-1 md:px-5 md:py-4"
              >
                <p
                  class="w-full text-sm text-newGrey whitespace-nowrap opacity-65"
                >
                  {{ spec.title }}
                </p>
                <p class="w-full whitespace-nowrap text-realBlack">
                  <!-- <br /> -->
                  {{ spec.value }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full" v-if="showAll">
            <div
              class="w-full mt-8"
              v-for="other in otherSpecs"
              :key="other.title"
            >
              <h6 class="text-lg font-belgiano md:text-2xl mb-2">
                {{ other.title }}
              </h6>
              <div
                class="flex flex-wrap items-start w-full gap-4 pl-2 mt-2 md:px-7 md:pl-0 md:gap-8"
              >
                <div
                  class="vehSpec"
                  v-for="otherValue in other.values"
                  :key="otherValue"
                >
                  <span class="bigDot"></span>
                  <p>{{ otherValue }}</p>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="px-2 py-4 mt-3">
                <p class="text-lg">
                  <span class="text-sm text-newGrey opacity-65">Warranty</span>
                  <br />
                  36 Months
                </p>
              </div>
              <div class="w-full mt-3">
                <div class="inline-block py-2 pl-3 pr-4 bg-green bg-opacity-10">
                  <p class="font-inter leading-[26px] text-green">
                    Home charging available
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <button
              v-if="!showAll"
              @click="showAll = true"
              class="black-btn long mt-7"
            >
              See More
            </button>
            <div class="flex items-center justify-between w-full" v-else>
              <router-link :to="{ name: 'order-sill' }">
                <button class="black-btn long mt-7">Order Now</button>
              </router-link>
              <button
                class="white-btn long mt-7 black-border"
                @click="showAll = false"
              >
                See Less
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import gsap from "gsap";
import col1 from "@/assets/images/savannah/colors/night_black.png";
import col2 from "@/assets/images/savannah/colors/cornet_white.png";
import col3 from "@/assets/images/savannah/colors/particle_blue.png";
import col4 from "@/assets/images/savannah/colors/dust-free_silver.png";
import car1 from "@/assets/images/savannah/colors/black.png";
import car2 from "@/assets/images/savannah/colors/white.png";
import car3 from "@/assets/images/savannah/colors/blue.png";
import car4 from "@/assets/images/savannah/colors/silver.png";
export default {
  data() {
    return {
      lhero: false,
      showAll: false,
      swipe: {
        touchstartX: 0,
        touchendX: 0,
      },
      specs: [
        { title: "Charging", value: "AC/DC" },
        { title: "Length(mm)", value: "4980" },
        { title: "Width(mm)", value: "1980" },
        { title: "Height (mm)", value: "1450" },
        { title: "Wheelbase(mm)", value: "2980" },
        { title: "Tire Specifications", value: "245/45 R19" },
        { title: "CLTC EV Range (km)", value: "200" },
        { title: "CLTC Total Range (km)", value: "1160" },
        { title: "Battery Type", value: "Ternary Lithium Battery" },
        { title: "Battery Capacity (kWh)", value: "43.51" },
        { title: "DC Fast Charging Time (30%-80%)", value: "≤35min" },
        { title: "Motor Type", value: "Permanent magnet synchronous" },
        { title: "Total Motor Power (kW)", value: "170" },
        { title: "Total Motor Torque (N·m)", value: "310" },
        { title: "Acceleration Time (s) (0-100km/h)", value: "6.9" },
        { title: "Maximum Speed (km/h)", value: "185" },
        { title: "Fuel Tank Capacity", value: "45L" },
        { title: "Drive Type", value: "RWD" },
      ],
      otherSpecs: [
        {
          title: "Power System",
          values: [
            "Vehicle-to-Load Function (V2L)",
            "Battery Intelligent Thermal Management System",
            "Extended Range Electric Drive System (1.5L)",
          ],
        },
        {
          title: "Chassis System",
          values: [
            "Electric Power Steering (EPS)",
            "Electric Parking Brake (EPB)",
            "Autohold",
            "Integrated Brake System (IDB)",
            "One-Pedal Driving Mode",
            "Emergency Tire Repair Kit (Tire Sealant)",
          ],
        },
        {
          title: "Exterior",
          values: [
            "Panoramic Sunroof (Insulated)",
            "Frameless Doors",
            "Passive Entry (PE)",
            "Passive Start (PS)",
            "Power Tailgate",
            "Intelligent Remote Key (Bluetooth)",
            "Exterior Mirror Rearview Auto Folding",
            "Exterior Rearview Mirror with Memory Function",
            "Automatic Rain-Sensing Wipers",
          ],
        },
        {
          title: "Light",
          values: [
            "Intelligent Lighting Interaction",
            "LED Headlights",
            "LED Taillights",
            "LED Daytime Running Lights",
            "Automatic Headlights",
            "Charging Status Light Indicator  ",
            "LED Reading Lights",
            "",
          ],
        },
        {
          title: "Interior",
          values: [
            "Electronic Column Shift",
            "Microfiber-skin Steering Wheel",
            "Manual Adjustable Steering Wheel (4-way)",
            "USB+Type-C Port",
            "Wireless Phone Charging",
            "Driver-side Window One-Touch Up/Down with Anti-Pinch",
            "Sunvisors with Vanity Mirror/light",
          ],
        },
        {
          title: "Seat",
          values: [
            "Artificial Leather Seats",
            "Driver's Seat 8-Way Power Adjustment",
            "Front Passenger Seat 6-Way Power Adjustment",
            "Front Seat with Welcome& Memeroy Function",
            "Front Seat Heating",
          ],
        },
        {
          title: "Air Conditioning",
          values: [
            "Dual-Zone Air Conditioning",
            "Hidden Electrical Air Outlets",
            "Second-Row Air Outlets",
            "HEPA (N95) Filter",
          ],
        },
        {
          title: "Safety",
          values: [
            "Electronic Stability Control (ABS/EBD/EBA)",
            "Traction Control (ASR/TCS/TRC, etc.)",
            "Hill Start Assist (HAC)",
            "Hill Descent Control (HDC)",
            "Driver/Passenger Front Airbags",
            "Front Side Airbags",
            "Front/Rear Head-position Air Curtain",
            "Direct Tire Pressure Monitoring System",
            "Pre-tensioned Force Limiting Seatbelt",
            "Front  Seatbelt Unfastened Reminder",
            "Rear Seatbelt Unfastened Reminder",
            "Second-Row Child Seat Interfaces  With ISOFIX/LATCH",
            "Electric Child Safety Locks",
            "Parking Radar (Front/Back)",
            "Rear View Camera with Trajectory Guide Line",
          ],
        },
        {
          title: "Advanced Driving Assistance System",
          values: [
            "Ultrasonic Radar",
            "Around View Camera",
            "Cruise Control (CC)",
            "4G",
            "Hidden Type Instrument Panel",
            "17.6-inch Central Control Screen",
            "12 Speakers",
            "Bluetooth Music/Call",
          ],
        },
      ],
      spectrum: [
        {
          icon: col1,
          car: car1,
        },
        {
          icon: col2,
          car: car2,
        },
        {
          icon: col3,
          car: car3,
        },
        {
          icon: col4,
          car: car4,
        },
      ],
      activeSpectrum: 0,
    };
  },
  computed: {
    mobile() {
      return window.innerWidth <= 768;
    },
    prcs() {
      return this.$store.getters.dispPrices;
    },
  },
  methods: {
    handleSwipe() {
      if (this.swipe.touchendX < this.swipe.touchstartX) {
        if (this.activeSpectrum < this.spectrum.length - 1) {
          this.changeCarTo(this.activeSpectrum + 1);
        }
      }
      if (this.swipe.touchendX > this.swipe.touchstartX) {
        if (this.activeSpectrum > 0) {
          this.changeCarTo(this.activeSpectrum - 1, true);
        }
      }
    },
    async changeCarTo(index, reverse = false) {
      const changeTo = async (index) => {
        this.activeSpectrum = index;
      };
      if (reverse) {
        if (this.activeSpectrum !== 0) {
          gsap.to(".switchCont", {
            xPercent: 110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: -110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      } else {
        if (this.activeSpectrum !== index) {
          gsap.to(".switchCont", {
            xPercent: -110,
            duration: 0.4,
            ease: "Power4.in",
            async onComplete() {
              await changeTo(index);
              gsap.set(".switchCont", {
                xPercent: 110,
                onComplete() {
                  gsap.to(".switchCont", {
                    xPercent: 0,
                    duration: 0.6,
                    ease: "Power4.out",
                  });
                },
              });
            },
          });
        }
      }
    },
  },
  watch: {
    lhero() {
      if (this.lhero === true) {
        this.$refs.swipeArea.addEventListener("touchstart", (e) => {
          this.swipe.touchstartX = e.changedTouches[0].screenX;
        });
        this.$refs.swipeArea.addEventListener("touchend", (e) => {
          this.swipe.touchendX = e.changedTouches[0].screenX;
          this.handleSwipe();
        });
      }
    },
  },
};
</script>

<style>
.heroBg {
  background-image: url("@/assets/images/sill/hero.png");
  background-size: cover;
  background-position: center;
}
@media (max-width: 768px) {
  .heroBg {
    background-position: 60%;
  }
}
.ovl {
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}
.ovlTop {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.vehSpec {
  @apply flex items-center gap-3 md:w-auto w-full;
}
.bigDot {
  @apply inline-block w-2 h-2 rounded-full bg-green bg-opacity-65;
}
</style>